var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading-content',{attrs:{"is-loading":_vm.isLoading}}),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"header-bg-variant":"transparent"}},[_vm._v(" "+_vm._s(_vm.user && ((_vm.user.id) + "." + (_vm.user.name || _vm.user.email)))+" "),_c('div',[_c('b-button',{staticClass:"text-white mr-2",on:{"click":function($event){return _vm.$router.push({ name: 'RolesList' })}}},[_vm._v(" "+_vm._s(_vm.$t("BACK"))+" ")]),_c('b-button',{staticClass:"text-white",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'editUserForm', params: { id: _vm.user.id, user: _vm.user } })}}},[_vm._v(" "+_vm._s(_vm.$t("EDIT"))+" ")])],1)]),_c('b-card-body',[_c('div',{staticClass:"row"},_vm._l((_vm.headers),function(headerValue,headerName){return _c('div',{key:headerName,staticClass:"col border-right",class:{
            'border-0': _vm.headers.length - 1 === headerName,
          }},[_c('h5',{staticClass:"font-sm font-weight-bold text-secondary pb-2"},[_vm._v(" "+_vm._s(_vm.$t(("USER." + headerValue)))+" ")]),('enabled confirmed'.includes(headerValue))?_c('p',[_c('b-badge',{staticClass:"text-white",attrs:{"variant":_vm.user && _vm.user[headerValue] === true ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(_vm.$t(("TABLE." + headerValue + "_" + (_vm.user[headerValue].toString().toLocaleUpperCase()))))+" ")])],1):(headerValue === 'duration')?_c('p',{staticClass:"font-sm"},[_vm._v(" "+_vm._s(_vm.user && _vm.$t(("TABLE." + (_vm.user[headerValue].type.toLocaleUpperCase())), { expires_on: _vm.user[headerValue].expires_on, begins_on: _vm.user[headerValue].begins_on, expires_after: _vm.user[headerValue].expires_after, }))+" ")]):_c('p',{staticClass:"font-sm"},[_vm._v(" "+_vm._s(_vm.user && _vm.user[headerValue])+" ")])])}),0)])],1),_c('div',{staticClass:"d-flex"},[_c('b-card',{staticClass:"my-4 w-50 mr-2",attrs:{"no-body":""}},[_c('b-card-header',[_vm._v(" Aktualne uprawnienia roli ")]),_c('b-card-body',[_c('div',{staticClass:"d-flex justify-content-between container"},[_c('b-form-input',{staticClass:"w-25",attrs:{"placeholder":_vm.$t('SEARCH')},model:{value:(_vm.search.configurator),callback:function ($$v) {_vm.$set(_vm.search, "configurator", $$v)},expression:"search.configurator"}}),_c('div',{staticClass:"buttons"},[(_vm.getTreeViewData)?_c('b-button',{staticClass:"text-white ml-3",on:{"click":function($event){return _vm.toggleConfigurator('configurator')}}},[_vm._v(" "+_vm._s(_vm.expanded.configurator ? _vm.$t("CLOSE") : _vm.$t("OPEN")))]):_vm._e()],1)],1),_c('v-treeview',{ref:"configurator",attrs:{"items":_vm.getTreeViewData,"search":_vm.search.configurator,"item-text":"title","return-object":"","open-on-click":true},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
          var item = ref.item;
return [(item.title === 'Attachment')?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-file")]):(item.children.length >= 1)?_c('v-icon',{attrs:{"small":""}},[_vm._v("far fa-folder-open")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-folder")])]}},{key:"label",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"caption",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.title)+" "+_vm._s(item.children && item.children.length ? ("(" + (item.children.length) + ")") : ""))])]}},{key:"append",fn:function(ref){
          var item = ref.item;
return [(item)?_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openModalPermissionsChange(item.entityName, item.entityId)}}},[_vm._v("far fa-edit")]):_vm._e()]}}])})],1)],1),_c('b-card',{staticClass:"my-4 w-50 ml-2",attrs:{"no-body":""}},[_c('b-card-header',[_vm._v(" Aktualne uprawnienia roli ")]),(_vm.getEntityTreeData.length >= 1)?_c('b-card-body',[_c('div',{staticClass:"d-flex justify-content-between container"},[_c('b-form-input',{staticClass:"w-25",attrs:{"placeholder":_vm.$t('SEARCH')},model:{value:(_vm.search.entityTree),callback:function ($$v) {_vm.$set(_vm.search, "entityTree", $$v)},expression:"search.entityTree"}}),_c('div',{staticClass:"buttons"},[(_vm.getEntityTree)?_c('b-button',{staticClass:"text-white ml-3",on:{"click":function($event){return _vm.toggleConfigurator('entityTree')}}},[_vm._v(" "+_vm._s(_vm.expanded.entityTree ? _vm.$t("CLOSE") : _vm.$t("OPEN")))]):_vm._e(),_c('b-button',{staticClass:"text-white ml-3",attrs:{"variant":"danger"},on:{"click":_vm.deleteTreeViewPermissionPermission}},[_vm._v(" "+_vm._s(_vm.$t("DELETE"))+" ")])],1)],1),_c('v-treeview',{ref:"entityTree",attrs:{"items":_vm.getEntityTreeData,"open-on-click":true,"search":_vm.search.entityTree,"item-text":"title","return-object":"","selectable":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"ml-2 mr-1"},[(item.title === 'Attachment')?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-file")]):(item.children.length >= 1)?_c('v-icon',{attrs:{"small":""}},[_vm._v("far fa-folder-open")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-folder")])],1)]}},{key:"label",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"caption",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.title)+" "+_vm._s(item.children && item.children.length ? ("(" + (item.children.length) + ")") : ""))])]}},{key:"append",fn:function(ref){
          var item = ref.item;
return [_c('TreeViewPermissions',{attrs:{"item":item},on:{"deletePermission":function (id) { return _vm.deleteTreeViewPermissionPermission(id); }}})]}}],null,false,3563741569),model:{value:(_vm.selectedTreeView),callback:function ($$v) {_vm.selectedTreeView=$$v},expression:"selectedTreeView"}})],1):_c('b-card-body',[_vm._v("Brak uprawnień. Dodaj user w konfiguratorze po lewej.")])],1)],1),_c('SwitchForm',{attrs:{"nameDispatch":"userConfigurator","name":"user","content":_vm.modalContent},on:{"render":_vm.dispatchStore}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }