<template>
  <div>
    <loading-content :is-loading="isLoading" />
    <b-card no-body>
      <b-card-header
        class="d-flex justify-content-between align-items-center"
        header-bg-variant="transparent"
      >
        {{ user && `${user.id}.${user.name || user.email}` }}
        <div>
          <b-button class="text-white mr-2" @click="$router.push({ name: 'RolesList' })">
            {{ $t("BACK") }}
          </b-button>
          <b-button
            class="text-white"
            variant="primary"
            @click="$router.push({ name: 'editUserForm', params: { id: user.id, user: user } })"
          >
            {{ $t("EDIT") }}
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="row">
          <div
            class="col border-right"
            v-for="(headerValue, headerName) in headers"
            :key="headerName"
            :class="{
              'border-0': headers.length - 1 === headerName,
            }"
          >
            <h5 class="font-sm font-weight-bold text-secondary pb-2">
              {{ $t(`USER.${headerValue}`) }}
            </h5>
            <p v-if="'enabled confirmed'.includes(headerValue)">
              <b-badge
                class="text-white"
                :variant="user && user[headerValue] === true ? 'success' : 'danger'"
              >
                {{ $t(`TABLE.${headerValue}_${user[headerValue].toString().toLocaleUpperCase()}`) }}
              </b-badge>
            </p>
            <p v-else-if="headerValue === 'duration'" class="font-sm">
              {{
                user &&
                $t(`TABLE.${user[headerValue].type.toLocaleUpperCase()}`, {
                  expires_on: user[headerValue].expires_on,
                  begins_on: user[headerValue].begins_on,
                  expires_after: user[headerValue].expires_after,
                })
              }}
            </p>
            <p v-else class="font-sm">
              {{ user && user[headerValue] }}
            </p>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <div class="d-flex">
      <!--CONFIGURATOR-->
      <b-card class="my-4 w-50 mr-2" no-body>
        <b-card-header> Aktualne uprawnienia roli </b-card-header>
        <b-card-body>
          <div class="d-flex justify-content-between container">
            <b-form-input class="w-25" v-model="search.configurator" :placeholder="$t('SEARCH')" />
            <div class="buttons">
              <b-button
                class="text-white ml-3"
                @click="toggleConfigurator('configurator')"
                v-if="getTreeViewData"
              >
                {{ expanded.configurator ? $t("CLOSE") : $t("OPEN") }}</b-button
              >
            </div>
          </div>
          <v-treeview
            :items="getTreeViewData"
            :search="search.configurator"
            item-text="title"
            return-object
            ref="configurator"
            :open-on-click="true"
          >
            <template v-slot:prepend="{ item }">
              <v-icon small v-if="item.title === 'Attachment'">fas fa-file</v-icon>
              <v-icon small v-else-if="item.children.length >= 1">far fa-folder-open</v-icon>
              <v-icon small v-else>fas fa-folder</v-icon>
            </template>
            <template slot="label" slot-scope="{ item }">
              <span class="caption" style="font-size: 12px">
                {{ item.title }}
                {{ item.children && item.children.length ? `(${item.children.length})` : "" }}</span
              >
            </template>
            <template v-slot:append="{ item }">
              <v-icon
                x-small
                v-if="item"
                class="ml-2"
                @click.stop="openModalPermissionsChange(item.entityName, item.entityId)"
                >far fa-edit</v-icon
              >
            </template>
          </v-treeview>
        </b-card-body>
      </b-card>

      <!--CONFIGURATOR END-->

      <!--ENTITYTREE-->
      <b-card class="my-4 w-50 ml-2" no-body>
        <b-card-header> Aktualne uprawnienia roli </b-card-header>
        <b-card-body v-if="getEntityTreeData.length >= 1">
          <div class="d-flex justify-content-between container">
            <b-form-input class="w-25" v-model="search.entityTree" :placeholder="$t('SEARCH')" />
            <div class="buttons">
              <b-button
                class="text-white ml-3"
                @click="toggleConfigurator('entityTree')"
                v-if="getEntityTree"
              >
                {{ expanded.entityTree ? $t("CLOSE") : $t("OPEN") }}</b-button
              >
              <b-button
                variant="danger"
                class="text-white ml-3"
                @click="deleteTreeViewPermissionPermission"
              >
                {{ $t("DELETE") }}
              </b-button>
            </div>
          </div>
          <v-treeview
            :items="getEntityTreeData"
            :open-on-click="true"
            :search="search.entityTree"
            v-model="selectedTreeView"
            item-text="title"
            return-object
            ref="entityTree"
            selectable
          >
            <template v-slot:prepend="{ item }">
              <div class="ml-2 mr-1">
                <v-icon small v-if="item.title === 'Attachment'">fas fa-file</v-icon>
                <v-icon small v-else-if="item.children.length >= 1">far fa-folder-open</v-icon>
                <v-icon small v-else>fas fa-folder</v-icon>
              </div>
            </template>
            <template slot="label" slot-scope="{ item }">
              <span class="caption" style="font-size: 12px">
                {{ item.title }}
                {{ item.children && item.children.length ? `(${item.children.length})` : "" }}</span
              >
            </template>
            <template v-slot:append="{ item }">
              <TreeViewPermissions
                :item="item"
                @deletePermission="(id) => deleteTreeViewPermissionPermission(id)"
              />
            </template>
          </v-treeview>
        </b-card-body>
        <b-card-body v-else>Brak uprawnień. Dodaj user w konfiguratorze po lewej.</b-card-body>
      </b-card>
      <!--VIEW ENTITYTREE END-->
    </div>
    <SwitchForm
      nameDispatch="userConfigurator"
      name="user"
      :content="modalContent"
      @render="dispatchStore"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingContent from "@/components/LoadingContent/LoadingContent.vue";
import { GET_CONFIGURATOR_ROLE } from "@/core/store/Roles/role.module";
import SwitchForm from "@/components/SwitchForm/SwitchForm.vue";
import TreeViewPermissions from "@/components/TreeView/TreeViewPermissions.vue";
import { DELETE_USER_PERMISSION, GET_USER } from "@/core/store/Users/user.module";

export default {
  name: "editUser",
  components: { TreeViewPermissions, SwitchForm, LoadingContent },
  data() {
    return {
      isLoading: true,
      headers: [
        "id",
        "email",
        "firstName",
        "lastName",
        "enabled",
        "confirmed",
        "createdAt",
        "updatedAt",
      ],
      searchConfigurator: null,
      search: {
        entityTree: null,
        configurator: null,
      },
      modalContent: {},
      expanded: {
        configurator: false,
        entityTree: false,
      },
      user: null,
      selectedTreeView: [],
    };
  },
  methods: {
    openModalPermissionsChange(entityName, entityId) {
      this.modalContent = { ...this.user, entityName, entityId };
      this.$bvModal.show("configurator");
    },
    toggleConfigurator(name) {
      this.expanded[name] = !this.expanded[name];
      this.$refs[name].updateAll();
      this.$refs[name].updateAll(this.expanded[name]);
    },
    deleteTreeViewPermissionPermission(id) {
      this.$bvModal.msgBoxConfirm("Are you sure?").then((value) => {
        if (value) {
          // single permission
          if (Array.isArray(id)) {
            for (let permID of id) {
              this.$store.dispatch(DELETE_USER_PERMISSION, permID).then(() => {
                if (permID === id.pop()) this.dispatchStore();
              });
            }
          }
          // selected permissions
          for (const selected of this.selectedTreeView) {
            for (const perm of selected.permissions) {
              this.$store.dispatch(DELETE_USER_PERMISSION, perm.id).then(() => {
                this.$store.dispatch("getUserPermissions", this.$route.params.id);
              });
            }
          }
        }
      });
    },
    async dispatchStore() {
      this.isLoading = false;
      this.$store.dispatch("getUserPermissions", this.$route.params.id).then(() => {
        this.$nextTick(() => {
          this.isLoading = false;
          this.$refs.entityTree.updateAll();
        });
      });
    },
  },
  computed: {
    getTreeViewData() {
      const items = [];
      for (let i in this.getConfiguratorRole) {
        // eslint-disable-next-line no-unused-expressions
        i !== "EntityAttachment" &&
          items.push({
            title: i,
            id: i,
            permissions: this.getConfiguratorRole[i].permissions,
            entityName: i,
            children: this.getConfiguratorRole[i].elements,
          });
      }
      return items;
    },
    getEntityTreeData() {
      const items = [];
      for (let i in this.getUserPermissions) {
        items.push({
          title: i,
          id: i,
          permissions: this.getUserPermissions[i].permissions,
          entityName: i,
          children: this.getUserPermissions[i].elements,
        });
      }
      return items.filter((i) => i.permissions.length >= 1 || i.children.length >= 1);
    },
    ...mapGetters(["getUser", "getConfiguratorRole", "getEntityTree", "getUserPermissions"]),
  },
  mounted() {
    this.user = this.$route.params.user;
    this.$store.dispatch("getUserPermissions", this.$route.params.id);
    if (!this.user) {
      this.isLoading = true;
      this.$store.dispatch(GET_USER, this.$route.params.id).then(() => {
        this.user = this.getUser;
        this.isLoading = false;
      });
    }
    this.$store.dispatch(GET_CONFIGURATOR_ROLE, this.$route.params.user).then(() => {
      this.isLoading = false;
    });
  },
};
</script>
